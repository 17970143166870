import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { getRssPath } from '../utils/routes';
import styled from 'styled-components';
import _ from 'lodash';
import cookie from 'js-cookie';
import CTALinkClick from '../utils/CTALinkClick';

//###################################################//
// Footer                                            //
//###################################################//

const PurpleText = styled.span`
  color: #7774ff;
  font-weight: bold;
`;

const FooterWebSiteName = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 37px;
  line-height: 97.83%;
  letter-spacing: -0.03em;
  color: #292930;

  @media (max-width: 940px) {
    font-size: 25px;
  }
`;

const FooterContainer = styled.div`
  position: relative;
`;
const FooterContent = styled.div`
  margin: 0 auto;
  max-width: 1120px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 74px;

  @media (max-width: 1119px) {
    padding: 0 40px;
  }

  @media (max-width: 940px) {
    margin-bottom: 38px;
    justify-content: center;
  }
`;
const FooterSection = styled.div``;

const FooterLinksSection = styled(FooterSection)`
  @media (max-width: 940px) {
    display: none;
  }
`;
const FooterLogoSection = styled.div`
  display: flex;
  align-items: center;
`;

import logo from '../../static/logo.png';
import logo2x from '../../static/logo@2x.png';

const FooterLogo = styled.div`
  margin-right: 16px;

  @media (max-width: 940px) {
    width: 51px;
    margin-right: 8px;

    > img {
      width: 100%;
      display: block;
    }
  }
`;
const FooterLinksList = styled.div`
  display: flex;
  margin-right: 160px;
  margin-top: 42px;

  @media (max-width: 1119px) {
    margin-right: 0;
  }
`;
const FooterLinksColumn = styled.div`
  width: 160px;
  margin-right: 32px;
`;
const FooterLinksColumnHeader = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #999fae;
  margin-bottom: 42px;
`;
const FooterLinksColumnItem = styled.a`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.025em;
  color: #292930;
  margin-bottom: 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
  transition: color 200ms;

  :hover {
    text-decoration: none;
    color: #5451c9;
  }
`;
const SubFooter = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 99.33%;
  letter-spacing: -0.025em;
  color: #999fae;
  padding: 42px 0 74px 16px;
  border-top: 1px solid #e3e6e9;
  margin: 0 auto;
  max-width: 1120px;

  @media (max-width: 1119px) {
    padding: 34px 40px 50px 40px;
  }
`;

const FooterCopyrights = styled.div`
  @media (max-width: 940px) {
    display: none;
  }
`;
const MFooterLegalLinks = styled.div`
  display: none;
  justify-content: space-between;

  @media (max-width: 940px) {
    display: flex;
  }
`;

const MFooterLegalLink = styled.a`
  font-family: Roboto;
  font-size: 13px;
  line-height: 22px;
  letter-spacing: -0.025em;
  text-decoration-line: underline;
  color: #7774ff;
  text-align: ${props => props.textAlign};
  transition: color 200ms;

  &:hover {
    color: #5451c9;
  }
`;

const CookiesBanner = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(20px);
  padding: 23px 40px;
  filter: drop-shadow(0px -4px 10px rgba(0, 0, 0, 0.05));
  z-index: 1;
  display: ${props => (props.shown ? 'block' : 'none')};
`;

const CookiesBannerContent = styled.div`
  max-width: 1120px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #525260;

  a {
    text-decoration-line: underline;
    color: #7774ff;
  }
`;

const CookiesAgreeButton = styled.div`
  padding: 29px 24px;
  position: relative;
  height: 52px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 2px solid #7774ff;
  border-radius: 80px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  color: #7774ff;
  cursor: pointer;
  text-decoration: none;
  width: 152px;
  margin: 0 78px 0 18px;

  :hover {
    text-decoration: none;
    color: #fff;
    background: #5451c9;
    border-color: #5451c9;
  }

  @media (max-width: 375px) {
    margin-right: 58px;
  }
`;

const Footer = () => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      site {
        siteMetadata {
          siteName
          emails {
            hello
          }
          socialLinks {
            telegramBot
            telegramChannel
            instagramBusiness
            facebookPage
          }
        }
      }
    }
  `);
  const siteName = _.get(data, 'site.siteMetadata.siteName');
  const telegramBot = _.get(data, 'site.siteMetadata.socialLinks.telegramBot');
  const helloEmail = _.get(data, 'site.siteMetadata.emails.hello');
  const telegramChannel = _.get(
    data,
    'site.siteMetadata.socialLinks.telegramChannel',
  );
  const twitterAccount = _.get(
    data,
    'site.siteMetadata.socialLinks.twitterAccount',
  );
  const instagramBusiness = _.get(
    data,
    'site.siteMetadata.socialLinks.instagramBusiness',
  );
  const facebookPage = _.get(
    data,
    'site.siteMetadata.socialLinks.facebookPage',
  );
  const [shouldShowCookies, setShouldShowCookies] = React.useState(
    cookie.get('cookies_banner') !== '1',
  );
  const onCookiesAgree = React.useCallback(() => {
    setShouldShowCookies(false);
    cookie.set('cookies_banner', '1', 365);
  }, []);
  React.useEffect(() => {
    window.fbAsyncInit = function() {
      FB.init({
        xfbml: true,
        version: 'v10.0',
      });
    };

    (function(d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/ru_RU/sdk/xfbml.customerchat.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  }, []);

  return (
    <FooterContainer>
      <FooterContent>
        <FooterSection>
          <FooterLogoSection>
            <FooterLogo>
              <img srcSet={`${logo} 1x, ${logo2x} 2x`} alt="Логотип Nerdia" />
            </FooterLogo>
            <FooterWebSiteName>
              {siteName}
              <PurpleText>.</PurpleText>
            </FooterWebSiteName>
          </FooterLogoSection>
        </FooterSection>
        <FooterLinksSection>
          <FooterLinksList>
            <FooterLinksColumn>
              <FooterLinksColumnHeader>Проект</FooterLinksColumnHeader>
              <FooterLinksColumnItem
                href={telegramBot}
                target="_blank"
                rel="noopener noreferrer"
                onClick={CTALinkClick('footer_link')}
              >
                Стань первым
              </FooterLinksColumnItem>
              <FooterLinksColumnItem href="/learn">Блог</FooterLinksColumnItem>
              <FooterLinksColumnItem href={getRssPath()}>
                RSS
              </FooterLinksColumnItem>
            </FooterLinksColumn>
            <FooterLinksColumn>
              <FooterLinksColumnHeader>Поддержка</FooterLinksColumnHeader>
              <FooterLinksColumnItem href={`mailto:${helloEmail}`}>
                Напишите нам
              </FooterLinksColumnItem>
              <FooterLinksColumnItem href="/terms">
                Пользовательское Соглашение
              </FooterLinksColumnItem>
              <FooterLinksColumnItem href="/privacy">
                Политика конфиденциальности
              </FooterLinksColumnItem>
            </FooterLinksColumn>
            <FooterLinksColumn>
              <FooterLinksColumnHeader>Сообщество</FooterLinksColumnHeader>
              <FooterLinksColumnItem
                href={telegramChannel}
                target="_blank"
                rel="noopener noreferrer"
              >
                Telegram
              </FooterLinksColumnItem>
              <FooterLinksColumnItem
                href={facebookPage}
                target="_blank"
                rel="noopener noreferrer"
              >
                Facebook
              </FooterLinksColumnItem>
              <FooterLinksColumnItem
                href={instagramBusiness}
                target="_blank"
                rel="noopener noreferrer"
              >
                Instagram
              </FooterLinksColumnItem>
            </FooterLinksColumn>
          </FooterLinksList>
        </FooterLinksSection>
      </FooterContent>
      <SubFooter>
        <FooterCopyrights>
          © {new Date().getFullYear()}, {siteName}.
        </FooterCopyrights>
        <MFooterLegalLinks>
          <MFooterLegalLink href="/terms" textAlign="left">
            Пользовательское Соглашение
          </MFooterLegalLink>
          <MFooterLegalLink href="/privacy" textAlign="right">
            Политика конфиденциальности
          </MFooterLegalLink>
        </MFooterLegalLinks>
      </SubFooter>
      <CookiesBanner shown={shouldShowCookies}>
        <CookiesBannerContent>
          <span>
            Мы используем куки.{' '}
            <a target="_blank" href="/cookies">
              Что&nbsp;это&nbsp;значит?
            </a>
          </span>
          <CookiesAgreeButton onClick={onCookiesAgree}>OK</CookiesAgreeButton>
        </CookiesBannerContent>
      </CookiesBanner>
      <div id="fb-root" />
      <div
        className="fb-customerchat"
        attribution="setup_tool"
        page_id="106044428139744"
        logged_in_greeting="Привет! Чем я могу помочь?"
        logged_out_greeting="Привет! Чем я могу помочь?"
      />
    </FooterContainer>
  );
};

export default Footer;
