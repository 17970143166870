const CTALinkClick = place => () => {
    if (window.amplitude != null) {
        window.amplitude
            .getInstance()
            .logEvent('try_alpha_button_click', { place });
        const date = new Date().toISOString().split('T')[0];
        const identify = new amplitude.Identify()
            .setOnce('alpha_first_try', date)
            .set('alpha_last_try', date)
            .add('alpha_try_count', 1);
        window.amplitude.identify(identify);
    }

    if (window.fbq == null) {
        return;
    }
    window.fbq('track', 'Lead');
};

export default CTALinkClick;
