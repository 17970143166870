import React from 'react';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import _ from 'lodash';
import defaultImage from '../content/assets/cover_en.png';

const SeoHelmet = ({
  description,
  title,
  keywords,
  image,
  location,
  lang = 'en',
  meta = [],
}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
            siteName
            facebookAppId
            social {
              twitter
            }
          }
        }
      }
    `,
  );
  const siteUrl = _.get(site, 'siteMetadata.siteUrl');
  const _siteUrl = `${siteUrl}${location.pathname}`;
  const siteName = _.get(site, 'siteMetadata.siteName');
  const _title = `${title || _.get(site, 'siteMetadata.title')} | ${siteName}`;
  const _description = description || _.get(site, 'siteMetadata.description');
  const _image = `${siteUrl}${defaultImage}`;
  const author = _.get(site, 'siteMetadata.social.twitter');
  const facebookAppId = _.get(site, 'siteMetadata.facebookAppId');

  return (
    <Helmet
      defer={false}
      htmlAttributes={{
        lang,
      }}
      title={_title}
      meta={_.compact([
        _title && {
          property: `og:title`,
          content: _title,
        },
        _title && {
          name: `twitter:title`,
          content: _title,
        },
        _description && {
          property: `og:description`,
          content: _description,
        },
        _description && {
          name: `description`,
          content: _description,
        },
        _description && {
          name: `twitter:description`,
          content: _description,
        },
        _siteUrl && {
          property: `og:url`,
          content: _siteUrl,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        author && {
          name: `twitter:creator`,
          content: author,
        },
        keywords &&
          keywords.length > 0 && {
            name: `keywords`,
            content: keywords.join(`, `),
          },
        _image && {
          property: 'og:image',
          content: _image,
        },
        _image && {
          name: 'twitter:image',
          content: _image,
        },
        facebookAppId && {
          property: 'fb:app_id',
          content: facebookAppId,
        },
        ...meta,
      ])}
    />
  );
};

export default SeoHelmet;
