const _ = require('lodash');

const getLearnPath = (slug, lang) => {
    return _.compact([
        lang != null && lang !== 'en' && `/${lang}`,
        '/learn',
        slug != null && `/${slug}`,
    ])
        .join('')
        .replace(/\/+/g, '/');
};
const getLearnPagePath = (index, prefix, lang) => {
    if (prefix == null) {
        return index === 1
            ? getLearnPath(null, lang)
            : getLearnPath(`/page/${index}`, lang);
    }

    return index === 1
        ? getLearnPath(`/${prefix}`, lang)
        : getLearnPath(`/${prefix}/page/${index}`, lang);
};
const getRssPath = () => '/rss.xml';

module.exports = {
    getLearnPath,
    getLearnPagePath,
    getRssPath,
};
